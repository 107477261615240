.main-heading {
  color: #292929;
  font-size: 50px !important;
  font-weight: 800;
  font-family: "Lato", sans-serif;
  margin-top: 25px;
}

.heading-span {
  color: #00aeef;
  text-align: l;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 13px !important;
  height: 13px !important;
  margin-right: 3px;
  margin-left: 3px;
  margin-bottom: -15px;
  cursor: pointer;
  background-color: #00aeef !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 50% !important;
}

.margin-devops {
  margin-top: -20px;
}

.team-width {
  width: 474px;
}

.whitelabe-width {
  width: 413px;
}
.background-img {
  background-image: url(../../assets/image/1x/main-header-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.service-background-img {
  background-image: url(../../assets/image/1x/mid-bg.png);
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: 100% 100%;
  display: block;
}

.font-p-size {
  font-weight: 500;
  font-size: 15px;
}

.button-color {
  color: white !important;
  background-color: #00aeef !important;
  border-radius: 50px !important;
  width: 150px;
  height: 45px;
  margin-bottom: 10%;
  border-color: transparent !important;
}

.button-color:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.button-2-color:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.button-2-color {
  color: white !important;
  background-color: #00aeef !important;
  border-radius: 50px !important;
  border-color: transparent !important;
  width: 200px;
  height: 45px;
  margin-bottom: 10%;
  margin-left: 3%;
}
.heading-home {
  font-family: "Lato", sans-serif;
}

.join-gradiant {
  background: #e9fffa;
  background: linear-gradient(
    174deg,
    rgba(255, 255, 255, 1) 62%,
    rgba(233, 255, 250, 1) 64%
  );
}

.subscribe-search1 {
  border-radius: 50px !important;
  box-shadow: 0 2px 2px rgba(160, 157, 157, 0.25),
    0 2px 2px rgba(168, 156, 156, 0.22);
  z-index: 1;
  position: absolute;
  height: 35px;
  width: 350px;
  height: 40px;
}

.subscribe-search-margin {
  padding-right: 50px;
}

.subscribe-button1 {
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #00aeef;
  border: none;
  border-radius: 50px !important;
  width: 150px;
  z-index: 1;
  margin-left: 350px !important;
  height: 40px;
  margin-top: 4px;
}
.subscribe-button1:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border: 1px solid #00aeef !important;
  line-height: 30px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  justify-content: center;
}
.test-background-img {
  background-image: url(../../assets/image/1x/logo-2.png);
  background-repeat: no-repeat !important;
  background-position: left;
  background-size: 100% 100%;
}

.server1 {
  animation-name: server1;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  color: white;
}
.server-width {
  width: 400px !important;
}

@keyframes server1 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
.service-img2 {
  background-image: url(../../assets/image/1x/mid-bg.png);
  background-repeat: no-repeat;
  width: 100%;
}
.text-watermark {
  background-image: url(../../assets/image/1x/water-mark.png);
  background-repeat: no-repeat;
  background-position: center;
}
.web-deliver-logo {
  background-image: url(../../assets/image/1x/logo-2.png);
  background-repeat: no-repeat;
  background-size: 130px;
  background-position: left;
}
.service-we-deliver {
  background-image: url(../../assets/image/1x/Asset2.png);
  background-repeat: no-repeat;
  background-size: 85px;
  background-position: 538px 10px;
}
.what-you-get {
  background-image: url(../../assets/image/1x/Asset2.png);
  background-repeat: no-repeat;
  background-size: 85px;
  background-position: 230px 0px;
}

.offers-width {
  width: 600px !important;
}
@media screen and (max-width: 400px) {
  .get-text-center {
    text-align: center;
  }
  .offers-width {
    width: 350px !important;
  }
  .background-img {
    background-image: url(../../assets/image/1x/main-header-bg.png);
    background-repeat: no-repeat;
    background-size: 0% 0%;
    background-position: center;
  }
  .main-heading {
    color: #292929;
    font-size: 40px !important;
    font-weight: 800;
    font-family: "Lato", sans-serif;
    margin-top: 25px;
  }
  .animation-width {
    width: 300px;
    margin-left: 50px;
  }
  .team-width {
    width: 350px;
  }

  .server-width {
    width: 380px !important;
  }
  .web-deliver-logo {
    background-image: url(../../assets/image/1x/logo-2.png);
    background-repeat: no-repeat;
    background-size: 0px;
    background-position: 10px 10px;
  }
  .what-you-get {
    background-image: url(../../assets/image/1x/Asset2.png);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 100px 40px;
  }
  .service-we-deliver {
    background-image: url(../../assets/image/1x/Asset2.png);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 180px 30px;
  }
  .subscribe-search-responsive400 {
    border-radius: 50px !important;
    box-shadow: 0 2px 2px rgba(160, 157, 157, 0.25),
      0 2px 2px rgba(168, 156, 156, 0.22);
    z-index: 1;
    position: absolute;
    height: 35px;
    width: 225px !important;
  }
  .subscribe-button-responsive400 {
    margin-right: 80px;
  }
  .button-2-color400 {
    color: white !important;
    background-color: #00aeef !important;
    border-radius: 50px !important;
    width: 150px;
    height: 45px;
    margin-bottom: 10%;
    margin-left: 3%;
  }
  .subscribe-button1 {
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #00aeef;
    border: none;
    border-radius: 50px !important;
    width: 150px;
    z-index: 1;
    margin-left: 250px !important;
    height: 35px;
    margin-top: 4px;
  }
  .subscribe-to-newsletter {
    margin-left: 0px !important;
  }
  .animation-img-responsive400 {
    display: none;
  }
}

@media screen and (max-width: 412px) {
  .offers-width {
    width: 350px !important;
  }
  .main-heading {
    color: #292929;
    font-size: 40px !important;
    font-weight: 800;
    font-family: "Lato", sans-serif;
    margin-top: 25px;
  }
  .button-2-color412 {
    color: white !important;
    background-color: #00aeef !important;
    border-radius: 50px !important;
    width: auto;
    height: 45px;
    margin-bottom: 10%;
    margin-left: 3%;
  }
  .animation-img-responsive412 {
    display: none;
  }

  table#homepageform td {
    display: block;
  }
  table#homepageform td input,
  table#homepageform td select {
    margin: 0px 0px 15px !important;
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .button-2-color360 {
    color: white !important;
    background-color: #00aeef !important;
    border-radius: 50px !important;
    width: 150px !important;
    height: 45px;
    margin-bottom: 10%;
    margin-left: 3%;
  }
  .button-color {
    color: white !important;
    background-color: #00aeef !important;
    border-radius: 50px !important;
    width: 126px;
    height: 45px;
    margin-bottom: 10%;
    border-color: transparent !important;
  }
  .animation-img-responsive412 {
    display: none;
  }
  .font-p-size {
    font-weight: 500;
    font-size: 14px;
  }

  table#homepageform td {
    display: block;
  }
  table#homepageform td input,
  table#homepageform td select {
    margin: 0px 0px 15px !important;
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .server-width {
    width: 360px !important;
  }
  .button-2-color375 {
    color: white !important;
    background-color: #00aeef !important;
    border-radius: 50px !important;
    width: 150px;
    height: 45px;
    margin-bottom: 10%;
    margin-left: 3%;
  }
  .server-width {
    width: 370px;
  }
  .animation-img-responsive375 {
    display: none;
  }
  .font-p-size {
    font-weight: 500;
    font-size: 14px;
  }

  table#homepageform td {
    display: block;
  }
  table#homepageform td input,
  table#homepageform td select {
    margin: 0px 0px 15px !important;
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .subscribe-search-responsive428 {
    border-radius: 50px !important;
    box-shadow: 0 2px 2px rgba(160, 157, 157, 0.25),
      0 2px 2px rgba(168, 156, 156, 0.22);
    z-index: 1;
    position: absolute;
    height: 35px;
    width: 230px;
  }
  .what-you-get {
    background-image: url(../../assets/image/1x/Asset2.png);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 25px 30px;
  }
  .web-deliver-logo {
    background-image: url(../../assets/image/1x/logo-2.png);
    background-repeat: no-repeat;
    background-size: 0px;
    background-position: 10px 10px;
  }
  .service-we-deliver {
    background-image: url(../../assets/image/1x/Asset2.png);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 190px 30px;
  }
  .subscribe-button-responsive428 {
    margin-right: 80px;
  }
  .animation-img-responsive412 {
    display: none;
  }

  table#homepageform td {
    display: block;
  }
  table#homepageform td input,
  table#homepageform td select {
    margin: 0px 0px 15px !important;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .server1 {
    display: none;
  }
  .background-imgs {
    display: none;
  }
}

@media only screen and (min-width: 1440px) {
  /* / styles for browsers larger than 1440px; / */
  .what-you-get {
    background-image: url(../../assets/image/1x/Asset2.png);
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: 310px 10px;
  }
  .subscribe-to-newsletter {
    margin-left: 90px !important;
  }

  .server400-width {
    width: 571px !important;
  }
  .devops-responsive1440 {
    width: 548px !important;
  }
  .devsecops-responsive1440 {
    width: 548px !important;
  }
  .team-responsive1440 {
    width: 476px !important;
  }
}
@media only screen and (min-width: 2000px) {
  /* / for sumo sized (mac) screens / */
}
@media only screen and (max-device-width: 480px) {
  /* / styles for mobile browsers smaller than 480px; (iPhone) / */
}
@media only screen and (device-width: 768px) {
  /* / default iPad screens / */
}
/* / different techniques for iPad screening / */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* / For portrait layouts only / */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* / For landscape layouts only / */
}

.service-headings {
  font-size: 19.5px;
  /* margin-top: 5%; */
}
.none {
  color: transparent;
}
.subscribe-to-newsletter {
  margin-left: 55px;
}

.get-margin {
  margin-top: 70px;
}


.ddevops50percent{
  background-image: url(../../assets/image/ddevops50poff.png);
  position: static;
  z-index: 1;
  padding: 160px 0px 125px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
